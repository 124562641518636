.slider__slides_slide {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    list-style: none;

    &-active {
        z-index: 10;
    }

    & img {
        width: 100%;
        height: inherit;
    }
}
