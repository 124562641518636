.page-list-tests {
    padding: 20px 50px;
    font-weight: 600;

    &__container {
        justify-content: center;
    }

    &__pagination {
        text-align: center;
    }
}