.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;

    & > li {
        display: inline;


        & > a {
            position: relative;
            float: left;
            margin-left: -1px;
            padding: 6px 12px;
            line-height: 1.42857143;
            color: #337ab7;
            text-decoration: none;
            background-color: #fff;
            border: 1px solid #ddd;
        }




        &:first-child > a {
            margin-left: 0;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }


    }

    & > .active > a,
    & > .active > a:hover,
    & > .active > a:focus {
        z-index: 3;
        color: #fff;
        cursor: default;
        background-color: #337ab7;
        border-color: #337ab7;
    }


}