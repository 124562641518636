.block-main {
    display: inline-block;
    position: absolute;
    z-index: 1;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: left .5s;
    transition-timing-function: cubic-bezier(0,0,.2,1);

    &.show-side-menu {
        margin-left: 220px;
    }

    &__content {
        display: inline-block;
        position: absolute;
        top: 200px;
        bottom: 0;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.slider {
    display: inline-block;
    position: absolute;
    height: 200px;
    width: 100%;
}

/*************** @media ***************/

@media screen and (max-width: 576px) {
}

@media screen and (max-width: 767px) {
    .block-main {
        left: -220px;
        transition: left .5s;
        transition-timing-function: cubic-bezier(0,0,.2,1);

        &__content {
            top: 0;
        }
    }

    .block-main.side-menu-show {
        left: 0;
        transition: left .5s;
        transition-timing-function: cubic-bezier(0,0,.2,1);
    }

    .slider {
        display: none;
    }
}

/*************** SCROLLBAR STYLE ***************/

.block-main__content::-webkit-scrollbar{
    width: 10px;
    height:10px
}

.block-main__content::-webkit-scrollbar-track{
    background: #c2ddf3;
    -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.3)
}

.block-main__content::-webkit-scrollbar-thumb{
    background: #6589a8;
    border-radius: 6px; border: 3px solid rgba(255,255,255,0);
    background-clip:padding-box
}

.block-main__content::-webkit-scrollbar-thumb:vertical{
    min-height:50px
}