.page-main {
    padding: 20px 50px;

    &__gallery {
        &_container {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-template-rows: repeat(5, 180px);
        }

        &_container > div {
            margin: 1em 0.5em;
        }

        &_container img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    
        &_container-img01 {
            grid-column: 1/3;
        }
    
        &_container-img02 {
            grid-column: 3/4;
        }
    
        &_container-img03 {
            grid-column: 4/6;
        }
    
        &_container-img04 {
            grid-column: 1/6;
        }
    
        &_container-img05 {
            grid-column: 1/3;
        }
    
        &_container-img06 {
            grid-column: 3/4;
        }
    
        &_container-img07 {
            grid-column: 4/6;
        }
    }

    & p {
        text-align: justify;
    }
}


