.block-side-nav {
    display: none;
    position: absolute;
    z-index: 7;
    top: 50px;
    left: 0;
    bottom: 0;
    width: 220px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #29363d;
    color: #4b646f;
    transition: left .5s;
    transition-timing-function: cubic-bezier(0,0,.2,1);

    &.show-side-menu {
        display: inline-block;
    }
}

/*************** @media ***************/

@media screen and (max-width: 767px) {
    .block-side-nav {
        left: -220px;
        transition: left .5s;
        transition-timing-function: cubic-bezier(0,0,.2,1);
    }

    .block-side-nav.show-mobile-menu {
        left: 0;
        transition: left .5s;
        transition-timing-function: cubic-bezier(0,0,.2,1);
    }
}

/*************** SCROLLBAR STYLE ***************/

.block-side-nav::-webkit-scrollbar {
    width: 7px;
    background: transparent;
}

.block-side-nav::-webkit-scrollbar-track {
    background: transparent;
}

.block-side-nav::-webkit-scrollbar-thumb {
    background: rgba(211,221,221,0.3);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}