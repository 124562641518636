.item-route, .item-submenu, .item-link {
    display: block;
    height: 2.5rem;
    padding: 2px 0 2px 10px;
    line-height: 2.2rem;
    color: #ddd;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        background-color: rgba(255, 255, 255, 0.15);
    }
}

.item-submenu {
    & span::after {
        float: right;
        margin-right: 10px;
        content: '\25c0';
    }
}

.item-separator {
    display: block;
    height: 1px;
    margin: 0 3px;
    border-top: 1px solid #fff;
}

.level-menu-1 {
    padding-left: 1.5em;
    background-color: #33444d;
}

.level-menu-2 {
    padding-left: 3em;
    background-color: #3d515c;
}

.level-menu-3 {
    padding-left: 4.5em;
    background-color: #475f6b;
}

.level-menu-4 {
    padding-left: 6em;
    background-color: #526c7a;
}