.slider {
/*
    position: relative;
    width: 100%;
*/

    &__slides {
        margin: 0;
        padding: 0;
    }

    &__dots {
        position: relative;
        display: flex;
        justify-content: center;
        margin: 0;
        padding-top: 10px;
    }
}
