.page-list-tests {
    &__container {
        &_item {
            height: 200px;
            width: 200px;
            margin-top: 1em;
            padding: 10px;
            border-right: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
        }
    }
}