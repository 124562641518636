.page-categories-tests {
    padding: 20px 50px;
    font-weight: 600;

    &__content {
        &_block {
            margin: 0 15px 15px 0;
            padding: 15px;
            background-color: beige;
        }

        &_block h3 {
            margin-top: 0;
            text-decoration: underline;
        }

        &_block-sub {
            margin: 0 0 5px 15px;
        }
    }
}